body{
    margin: 0;
    padding: 0;
}

.ant-form-item {
    margin-bottom: 8px;
}

.projectSwitcher {
    box-shadow: unset !important;
}

@media screen and (max-width: 576px) {
    .ApplicationsListRowStyle .ant-card-body {
        padding: 8px 0 !important;
    }
}
