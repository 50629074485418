@font-face {
font-family: '__localInterFont_e70486';
src: url(/_next/static/media/f8c4c2644cc53633-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__localInterFont_e70486';
src: url(/_next/static/media/929a7e516843282c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__localInterFont_e70486';
src: url(/_next/static/media/0c6c55d5bd465623-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: '__localInterFont_Fallback_e70486';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_e70486 {font-family: '__localInterFont_e70486', '__localInterFont_Fallback_e70486'
}

body{
    margin: 0;
    padding: 0;
}

.ant-form-item {
    margin-bottom: 8px;
}

.projectSwitcher {
    box-shadow: unset !important;
}

@media screen and (max-width: 576px) {
    .ApplicationsListRowStyle .ant-card-body {
        padding: 8px 0 !important;
    }
}

